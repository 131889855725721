import React from "react"
import Layout from "../components/Layout"
import Carouselcomp from "../components/Carouselcomp"
import { Row, Col, Container } from "react-bootstrap"
import { graphql } from "gatsby"
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe"
import { FaCalendarDay } from "@react-icons/all-files/fa/FaCalendarDay"
import { GiPencilBrush } from "@react-icons/all-files/gi/GiPencilBrush"
import { AiFillBulb } from "@react-icons/all-files/ai/AiFillBulb"
import { AiOutlineBulb } from "@react-icons/all-files/ai/AiOutlineBulb"
import Headmeta from "../components/Headmeta"
import useSitedata from "../components/useSitedata"
import ShareComp from "../components/ShareComp"

export default function ProjectDetails({ data }) {
  const {
    title,
    slug,
    month,
    year,
    surface,
    original,
    print,
    medium,
    totalImages,
  } = data.markdownRemark.frontmatter
  // console.log(typeof(data))
  // console.log(data)
  const projectsPageName = useSitedata("projectsPageName")
  const baseUrl = useSitedata("url")
  const url = `${baseUrl}/${projectsPageName}/${slug}`

  let imagesFiltered = []
  for (let i = 1; i <= totalImages; i++) {
    imagesFiltered.push(
      eval(
        `data.markdownRemark.frontmatter.image${i}.childImageSharp.gatsbyImageData`
      )
    )
  }
  // console.log(imagesFiltered);

  // since date is in iso string format, we need to find month and year from it to display
  // const dateTimeSeparated=date.split('T')
  // const yyymmddSeparated=dateTimeSeparated[0].split('-')
  // const year=yyymmddSeparated[0]
  // const month=yyymmddSeparated[1]
  // const day=yyymmddSeparated[2]
  const mdate = new Date(year, month - 1, 1)
  const monthName = mdate.toLocaleString("default", { month: "long" })
  // console.log(year,month)
  const projectDate = `${monthName} ${year}`

  let availability = ""
  if (print === 1 && original === 1) {
    availability = "Original & Print available"
  } else if (print === 1 && original === 0) {
    availability = "Print available"
  } else if (print === 0 && original === 1) {
    availability = "Original available"
  } else {
    availability = "For reference only"
  }

  const detailsText2 = availability

  let detailsText1 = ""
  if (surface === null) {
    detailsText1 = medium
  } else {
    detailsText1 = `${medium} on ${surface}`
  }

  return (
    <Layout>
      <Headmeta
        importedIn={`${title} | ${detailsText1} | ${
          projectsPageName[0].toUpperCase() + projectsPageName.slice(1)
        }`}
        importedUrl={`${projectsPageName}/${slug}`}
      />

      <Container>
        <Row className="pt-5">
          <Col md={8}>
            <Carouselcomp imagesAll={imagesFiltered} />
          </Col>
          <Col>
            <h2 className="pt-5">{title}</h2>
            <p className="text-muted">
              <FaGlobe />
              <span style={{ color: "rgb(0,124,165)" }}>
                {" "}
                Coordinarts:
              </span>{" "}
              <span style={{ color: "rgb(213,66,131)" }}>{slug}</span>{" "}
              {/* {`Coordinarts: ${slug}`} */}
              {/* <Button variant="primary" className='text-light' size='sm'>Copy</Button> */}
              <br />
              <FaCalendarDay /> {projectDate}
              <br />
              <GiPencilBrush /> {detailsText1}
              <br />
              {availability === "For reference only" ? (
                <span style={{ color: "rgb(190,190,190)", fontSize: "1.2em" }}>
                  <AiOutlineBulb />
                </span>
              ) : (
                <span style={{ color: "rgb(50,150,50)", fontSize: "1.2em" }}>
                  <AiFillBulb />
                </span>
              )}
              {detailsText2}
            </p>
            <ShareComp url={url} headColor="rgb(7,190,184)" />
            {/* <p className="text-muted">{data.markdownRemark.html}</p> */}
          </Col>
        </Row>
        <Row className="pt-5">
          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
        </Row>
      </Container>
      {/* <Container className="text-center p-5">
        <Row>
          <Col>
            <p>{shortBio}</p>
          </Col>
        </Row>
      </Container> */}
    </Layout>
  )
}

export const query = graphql`
  query ProjectDetails($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        slug
        month
        year
        surface
        original
        print
        medium
        totalImages
        image {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image1 {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image2 {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image3 {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image4 {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image5 {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image6 {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image7 {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image8 {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image9 {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image10 {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image11 {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image12 {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image13 {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image14 {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image15 {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image16 {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }
    }
  }
`
